import { template as template_d7f54595750146deb460bd03f31c7cf8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d7f54595750146deb460bd03f31c7cf8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

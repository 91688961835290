import { template as template_050e4bce2bfa43bd81d66f69eeee92cf } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_050e4bce2bfa43bd81d66f69eeee92cf(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;

import { template as template_7a0f41bbb2304b20a32b2b050e9fe433 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7a0f41bbb2304b20a32b2b050e9fe433(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
